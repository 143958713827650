<template>
  <div>
      <top></top>
      <!-- <img v-show="screenWidth > 740" class="banner" src="../assets/banner.jpg" />
      <img v-show="screenWidth < 740" class="banner" src="../assets/phone.jpg" /> -->
      <div class="mask">
          <div class="mask_ p-r">
              <div class="d-f j-b f18 cff">
                <div class="d-f"><img src="../assets/icon.png" /> 常见问题</div>
                 <span @click="support">点击查看更多帮助</span></div>
              <div class="cont">
                  <div class="d-f c33 f16 f-w"><img src="../assets/problem.png" alt=""> {{ newdata.question }}
                  </div>
                  <div class="c33 f16" v-html="newdata.content"></div>
              </div>
          </div>
      </div>
    <bottom></bottom>
  </div>
</template>

<script lang="ts" setup>
import top from "./top.vue";
import bottom from "./bottom.vue";
  import { ref, onMounted,onUnmounted,nextTick } from "vue";
  import {list} from "@/axios/index";
  import router from "@/router";
  import { useRoute } from "vue-router";
  const support = (path,index) => {
      router.push({
          path: "./support",
          query: {
          },
      });
  };
  const screenWidth = ref(window.innerWidth);
  const data = ref({
  oldScrollTop: 0,
  });
  const newdata_id= ref("")
  const newdata= ref({})
  const handleResize = () => {
  screenWidth.value = window.innerWidth;
  };
  const route = useRoute();
  const problem = ref([]);
  const lists = async () => {
      const { data }: any = await list({});
      if (data.code == 200) {
          problem.value = data.data
          for (let i = 0; i < data.data.length; i++) {
            if(data.data[i].id == newdata_id.value){
              newdata.value = data.data[i]
              newdata.value.content = newdata.value.content.replace(/<img/g,'<img style="max-width:100%;height:auto" ')
            }              
          }
      }
  };
  onMounted(() => {
    console.log(route.query.data, "asdasdasd");
    if (route.query.data != undefined) {
      newdata_id.value = JSON.parse(route.query.data);
    }
    lists()
    window.addEventListener('resize', handleResize);
    nextTick(() => {
      document.oncontextmenu = new Function("event.returnValue=false");
      document.onselectstart = new Function("event.returnValue=false");
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    })
  });
onUnmounted(() => {
window.removeEventListener('resize', handleResize);
});

</script>

<style lang="scss" scoped>
@media screen and (max-width: 740px) {
  .mask {
    margin-top:50px !important;
    .cff{
      height: 86px !important;
    }
  }
}
.mask {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin-top:101px;
  margin-bottom: 40px;
  .mask_ {
      margin: auto;
      max-width: 1200px;
      overflow: hidden;
      .cff {
          padding: 0 19px;
          height: 56px;
          background: linear-gradient(90deg, #2571FA, #2E84FD);
          span {
              cursor: pointer;
          }
          img{
            width: 17px;
            height: 17px;
            margin-right: 5px;
          }
      }

      .cont {
          padding: 31px 54px;
          background-color: #fff;

          div {
              &:nth-child(1) {
                  margin-bottom: 30px;

                  img {
                      width: 30px;
                      height: 30px;
                      margin-right: 10px;
                  }
              }
          }

          .buts {
              width: 160px;
              height: 48px;
              background: linear-gradient(90deg, #2571FA, #2E84FD);
              border-radius: 4px;
              line-height: 48px;
              margin: 160px auto 0 auto;
              cursor: pointer;
          }
      }
  }
}

.banner {
  width: 100%;
  height: auto;
  display: block;
}

</style>