import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
import "amfe-flexible";
import { fun } from "@/utils/flexible";
fun(document, window)
const app = createApp(App);
app.use(store).use(Vant).use(router).mount("#app");
