<template>
    <div>
      <div class="phone_height" v-show="screenWidth < 740"></div>
      <div class="p-f phone_nav" v-show="screenWidth < 740">
        <div class="d-f j-b phone">
          <img src="../assets/title.png" />
          <img @click="phoneshow = !phoneshow" src="../assets/caidan.png" />
        </div>
        <div :class="[phoneshow ? 'option option_' : 'option options']">
          <div :style="{ color: show == 0 ? '#01B8E1' : '#333', }" @click.stop="nav('',0)" class="d-f j-b"
            style="border-bottom: 1px solid #E9E9E9;">
            首页/home <img src="../assets/right1.png" />
          </div>
          <div :style="{ color: show == 1 ? '#01B8E1' : '#333', }" @click.stop="nav('support',1)" class="d-f j-b">
            帮助/help <img src="../assets/right1.png" />
          </div>
        </div>
        <div @click="phoneshow = false" v-show="phoneshow" style="background: rgba(0,0,0,0.4);height: 100vh;width: 100%;position: fixed;top: 0;left: 0;z-index: -1;"></div>
      </div>
      <div v-show="screenWidth > 740" class="nav p-f" :style="{
        background: background == 0 && !details_show ? 'rgba(46, 76, 100, 0)' : '#1A1C38',
      }">
        <div class="card-wrap d-f j-b">
          <img src="../assets/title.png" />
          <div class="cff f14 d-f">
            <div @click="nav('',0)" :class="[show == 0 ? 'tab p-r ta' : 'ta']" style="cursor: pointer;">首页</div>
            <div @click="nav('support',1)" :class="[show == 1 ? 'tab p-r' : '']" style="cursor: pointer;">帮助</div>
          </div>
        </div>
      </div>
      <div class="p-r" v-show="show == 0">
        <img v-show="screenWidth > 740" class="title_banner" src="../assets/title_banner.jpg" />
        <img v-show="screenWidth < 740" class="title_banner" src="../assets/phone1.jpg" />
        <div v-show="screenWidth > 740" class="p-a buts cff f18 d-f j-c" @click="lastVersionReleasess"><img src="../assets/but1.png" alt=""> Windows
          版下载</div>
        <div @click="windowup" class="updeta" v-show="screenWidth < 740">点击下载</div>
      </div>
    </div>
  </template>
  
  <script lang="ts" setup>
    import { ref, onMounted, onUnmounted, nextTick } from "vue";
    import {lastVersionReleases,} from "@/axios/index";
    import { useRoute } from "vue-router";
    import { showConfirmDialog } from "vant";
    import router from "@/router";
    const nav = (path,index) => {
        router.push({
            path: "./"+path,
            query: {
            },
        });
    };
    const windowup = (path,index) => {
      showConfirmDialog({
        title: "提示",
        message: "暂不支持手机下载，请使用电脑打开网站www.xinxikaoshi.com下载安装！",
        showCancelButton:false
      })
      .then(() => {
      })
    };
    
  const lastVersionReleasess = async () => {
    const { data }: any = await lastVersionReleases({});
    if (data.code == 200) {
      window.open(data.data.url)
    };
  };
  const details_show = ref<Number>(false);
  const phoneshow = ref<Number>(false);
  const show = ref<Number>('');
  const screenWidth = ref(window.innerWidth);
  const data = ref({
    oldScrollTop: 0,
  });
  
  const handleResize = () => {
    screenWidth.value = window.innerWidth;
  };
  const route = useRoute();
  onMounted(() => {
    console.log(route.path);
    if(route.path == '/'){
        show.value = 0
    }else{
        show.value = 1
    }
    if(route.path == '/details'){
        details_show.value= true
    }
    window.addEventListener("scroll", scrolling);
    window.addEventListener('resize', handleResize);
    nextTick(() => {
      document.oncontextmenu = new Function("event.returnValue=false");
      document.onselectstart = new Function("event.returnValue=false");
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    })
  });
  onUnmounted(() => {
    window.removeEventListener("scroll", scrolling);
    window.removeEventListener('resize', handleResize);
  });
  const background = ref(0);
  const scrolling = () => {
    let scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    let scrollStep = scrollTop - data.oldScrollTop;
    if (scrollTop >= 48) {
      background.value = 1;
    } else if (scrollTop <= 48) {
      background.value = 0;
    }
  
  };
  
  </script>
  
  <style lang="scss" scoped>
  .updeta{
    width: 300px;
    height: 80px;
    // background-color: #3082FF;
    background: linear-gradient(90deg, #067FFE, #0EC1E0);
    color: #fff;
    text-align: center;
    line-height: 80px;
    border-radius: 14px;
    position: absolute;
    left: 50%;
    margin-left: -150px;
    bottom: 20px;
    font-size: 30px;
    box-shadow: 0 2px 30px 0 rgba(255, 255, 255,0.2);
  }
  .Copyright {
    width: 100%;
    padding: 20px 0;
    background: #101729;
  }
  
  .phone_height {
    height: 47px;
  }
  
  .phone_nav {
    width: 100%;
    z-index: 101;
    top: 0;
  
    .phone {
      background: #1A2D64;
      padding: 31px 40px;
  
      img {
        &:nth-child(1) {
          width: 260px;
          height: auto;
        }
  
        &:nth-child(2) {
          width: 31px;
          height: 31px;
        }
      }
    }
  
    .options {
      height: 200px;
      animation: move3 0.5s ease-in-out forwards;
  
      @keyframes move3 {
        0% {
          height: 200px;
        }
  
        100% {
          height: 0;
        }
      }
    }
  
    .option_ {
      animation: move4 0.5s ease-in-out forwards;
  
      @keyframes move4 {
        0% {
          height: 0;
        }
  
        100% {
          height: 200px;
        }
      }
    }
  
    .option {
      overflow: hidden;
      background-color: #fff;
  
      div {
        margin: 0 40px;
        height: 99px;
      }
  
      img {
        width: 12px;
        height: 25px;
      }
    }
  }
  
  @media screen and (max-width: 740px) {
    .buttom {
      display: none;
    }
    .p-a{
      display: none;
    }
    .center {
      text-align: center;
      margin-left: 10px !important;
      margin-right: 0 !important;
      padding-bottom: 30px;
    }
  
    .ta {
      margin-right: 40px !important;
    }
  

  
    .introduction {
      display: block;
    }
  
    .but {
      height: auto !important;
    }
  
    .right {
      padding: 30px 0;
    }
  
    .introduction {
      margin-top: 20px !important;
    }
  }
  
  @media screen and (max-width: 1240px) {
    .nav {
      .card-wrap {
        width: 90%;
      }
    }
  }
  
  .ta {
    margin-right: 79px;
  }
  
  .tab {
    &::after {
      content: "";
      width: 28px;
      height: 4px;
      background: #3082FF;
      border-radius: 2px;
      bottom: -10px;
      left: 50%;
      margin-left: -14px;
      position: absolute;
    }
  }
  
  .nav {
    padding: 20px 0;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  
    img {
      width: 191px;
      height: auto;
    }
  }
  
  .title_banner {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .buts {
    width: 248px;
    height: 61px;
    background: #3082FF;
    border-radius: 6px;
    cursor: pointer;
    position: absolute;
    left: 50%;
    margin-left: -124px;
    bottom: 101px;
    transition: transform 0.5s ease-in-out;
    &:hover{
      box-shadow: 0px 2px 30px 0px rgba(0,0,0,0.1) !important;
      // color: #3082FF;
      transform: scale(1.05);
    }
    img {
      width: 27px;
      height: 27px;
      margin-right: 10px;
    }
  }
  
  .but {
    width: 100%;
    height: 300px;
    background-image: url("../assets/bottom.png");
    background-size: 100% 100%;
  
    .f28 {
      padding: 70px 0 20px 0;
    }
  
    .introduction {
      padding-top: 83px;
    }
  
    .but1 {
      width: 248px;
      height: 61px;
      background: #3082FF;
      border-radius: 6px;
      cursor: pointer;
      margin: 26px 0;
  
      img {
        width: 30px;
        height: 30px;
        margin: 0 5px 0 22px;
      }
  
      margin-bottom: 30px;
    }
  
    .but2 {
      cursor: pointer;
      width: 248px;
      height: 61px;
      background: #00DA81;
      border-radius: 6px;
  
      img {
        width: 30px;
        height: 30px;
        margin: 0 5px 0 22px;
      }
    }
  
    .center {
      margin-left: 46px;
      margin-right: 213px;
      line-height: 42px;
  
      .p-a {
        width: 1px;
        height: 152px;
        background: linear-gradient(0deg, #1B253C 0%, #B4B6BD 54%, #2C3446 100%);
        left: 285px;
        top: 0;
      }
    }
  
    .right {
      margin-left: 26px;
  
      img {
        width: 120px;
        height: 120px;
        border-radius: 6px;
        margin-bottom: 10px;
      }
    }
  }
  </style>