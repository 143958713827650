import request from "@/utils/axios";
// 获取问题
const list = (data: any) => {
  return request("/api/faq/list", "GET", data);
};
// 获取下周地址
const lastVersionReleases = (data: any) => {
  return request("/api/registerClient/lastVersionReleases", "GET", data);
};
export {
  list,
  lastVersionReleases
};
