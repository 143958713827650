<template>
    <div>
      <div class="but">
        <div class="card-wrap">
          <!-- <div class="cff f28 t-c">欢迎下载信考百分信息技术考试练习系统</div>
          <div class="cff f16 t-c">安装时，请关闭电脑杀毒软件和防火墙</div> -->
          <div class="d-f j-c introduction p-r">
            <div class="right t-c">
              <img src="../assets/ewm.jpg" />
              <div class="f14 cb4">扫码关注公众号</div>
            </div>
            <div class="center p-r">
              <div class="f18 cff">联系我们</div>
              <div class="f14 cb4">客服电话：18031089711</div>
              <div class="f14 cb4">投诉建议：xinkao666@126.com</div>
              <div class="f14 cb4">公司地址：河北省邯郸市肥乡区振兴路</div>
              <div class="p-a"></div>
            </div>
            <div class="buttom">
              <div class="cff f20">软件下载</div>
              <div class="d-f but1 cff f18" @click="lastVersionReleasess"><img src="../assets/but1.png" alt="">
                Windows客户端下载</div>
              <div class="cff f14">安装时，请关闭电脑杀毒软件和防火墙</div>
            </div>
          </div>
        </div>
      </div>
      <div style="color: rgba(255,255,255,.4);" class="t-c cff f14 Copyright">
        Copyright © 2019 河北早晨智能科技有限公司 <a style="color: rgba(255,255,255,.4);" href="https://beian.miit.gov.cn" target="_blank">冀ICP备2023029112号-1</a></div>
    </div>
  </template>
  
  <script lang="ts" setup>
  import { ref, onMounted, onUnmounted, nextTick } from "vue";
  import {
    lastVersionReleases,
  } from "@/axios/index";
  const lastVersionReleasess = async () => {
    const { data }: any = await lastVersionReleases({});
    if (data.code == 200) {
      window.open(data.data.url)
    };
  };
  const phoneshow = ref<Number>(false);
  const show = ref<Number>(0);
  const screenWidth = ref(window.innerWidth);
  const data = ref({
    oldScrollTop: 0,
  });
  
  const handleResize = () => {
    screenWidth.value = window.innerWidth;
  };
  
  onMounted(() => {
    window.addEventListener("scroll", scrolling);
    window.addEventListener('resize', handleResize);
    nextTick(() => {
      document.oncontextmenu = new Function("event.returnValue=false");
      document.onselectstart = new Function("event.returnValue=false");
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    })
  });
  onUnmounted(() => {
    window.removeEventListener("scroll", scrolling);
    window.removeEventListener('resize', handleResize);
  });
  const background = ref(0);
  const scrolling = () => {
    let scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    let scrollStep = scrollTop - data.oldScrollTop;
    if (scrollTop >= 48) {
      background.value = 1;
    } else if (scrollTop <= 48) {
      background.value = 0;
    }
  
  };
  
  </script>
  
  <style lang="scss" scoped>
  .Copyright {
    width: 100%;
    padding: 20px 0;
    background: #101729;
  }
  
  .phone_height {
    height: 47px;
  }
  
  .phone_nav {
    width: 100%;
    z-index: 101;
    top: 0;
  
    .phone {
      background: #1A2D64;
      padding: 31px 40px;
  
      img {
        &:nth-child(1) {
          width: 260px;
          height: auto;
        }
  
        &:nth-child(2) {
          width: 31px;
          height: 31px;
        }
      }
    }
  
    .options {
      height: 200px;
      animation: move3 0.5s ease-in-out forwards;
  
      @keyframes move3 {
        0% {
          height: 200px;
        }
  
        100% {
          height: 0;
        }
      }
    }
  
    .option_ {
      animation: move4 0.5s ease-in-out forwards;
  
      @keyframes move4 {
        0% {
          height: 0;
        }
  
        100% {
          height: 200px;
        }
      }
    }
  
    .option {
      overflow: hidden;
      background-color: #fff;
  
      div {
        margin: 0 40px;
        height: 99px;
      }
  
      img {
        width: 12px;
        height: 25px;
      }
    }
  }
  
  @media screen and (max-width: 740px) {
    .buttom {
      display: none;
    }
    .p-a{
      display: none;
    }
    .center {
      text-align: center;
      margin-left: 10px !important;
      margin-right: 0 !important;
      padding-bottom: 30px;
    }
  
    .ta {
      margin-right: 40px !important;
    }
  
    .buts {
      display: none;
    }
  
    .introduction {
      display: block;
    }
  
    .but {
      height: auto !important;
    }
  
    .right {
      padding: 30px 0;
    }
  
    .introduction {
      margin-top: 20px !important;
    }
  }
  
  @media screen and (max-width: 1240px) {
    .nav {
      .card-wrap {
        width: 90%;
      }
    }
  }
  
  .ta {
    margin-right: 79px;
  }
  
  .tab {
    &::after {
      content: "";
      width: 28px;
      height: 4px;
      background: #3082FF;
      border-radius: 2px;
      bottom: -10px;
      left: 50%;
      margin-left: -14px;
      position: absolute;
    }
  }
  
  .nav {
    padding: 20px 0;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  
    img {
      width: 191px;
      height: auto;
    }
  }
  
  .title_banner {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .buts {
    width: 248px;
    height: 61px;
    background: #3082FF;
    border-radius: 6px;
    cursor: pointer;
    position: absolute;
    left: 50%;
    margin-left: -124px;
    bottom: 101px;
    transition: transform 0.5s ease-in-out;
    &:hover{
      box-shadow: 0px 2px 30px 0px rgba(0,0,0,0.1) !important;
      // color: #3082FF;
      transform: scale(1.05);
    }
    img {
      width: 27px;
      height: 27px;
      margin-right: 10px;
    }
  }
  
  .but {
    width: 100%;
    height: 300px;
    background-image: url("../assets/bottom.png");
    background-size: 100% 100%;
  
    .f28 {
      padding: 70px 0 20px 0;
    }
  
    .introduction {
      padding-top: 83px;
    }
  
    .but1 {
      width: 248px;
      height: 61px;
      background: #3082FF;
      border-radius: 6px;
      cursor: pointer;
      margin: 26px 0;
  
      img {
        width: 30px;
        height: 30px;
        margin: 0 5px 0 22px;
      }
  
      margin-bottom: 30px;
    }
  
    .but2 {
      cursor: pointer;
      width: 248px;
      height: 61px;
      background: #00DA81;
      border-radius: 6px;
  
      img {
        width: 30px;
        height: 30px;
        margin: 0 5px 0 22px;
      }
    }
  
    .center {
      margin-left: 46px;
      margin-right: 213px;
      line-height: 42px;
  
      .p-a {
        width: 1px;
        height: 152px;
        background: linear-gradient(0deg, #1B253C 0%, #B4B6BD 54%, #2C3446 100%);
        left: 285px;
        top: 0;
      }
    }
  
    .right {
      margin-left: 26px;
  
      img {
        width: 120px;
        height: 120px;
        border-radius: 6px;
        margin-bottom: 10px;
      }
    }
  }
  </style>