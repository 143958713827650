var fun = function (doc: any, win: any) {
  var docEl = doc.documentElement,
    resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
    recalc = function () {
      var clientWidth = docEl.clientWidth;
      //这里是假设在750px宽度设计稿的情况下，1rem = 100px； //可以根据实际需要修改
      if (clientWidth < 680) {
        docEl.style.fontSize = 72 * (clientWidth / 750) + "px";
      } else {
        docEl.style.fontSize = 75 + "px";
      }
    };
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener("DOMContentLoaded", recalc, false);
};
fun(document, window);

export { fun };
