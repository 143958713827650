import axios from "axios";
import { h, ref } from "vue";
import { useRouter } from "vue-router";
import { showFailToast } from "vant";
import routes from "@/router/index";
import router from "@/router";
// const router = useRouter();
const type = (method: any) => {
  let sendData = "params";
  if (["PUT", "POST", "PATCH"].includes(method.toUpperCase())) {
    sendData = "data";
  }
  return sendData;
};
const instance = axios.create({
  // baseURL: "/api",
  baseURL: "https://exam.xinxikaoshi.com/exam",
  timeout: 30000,
});
/*请求拦截器*/
// let loading = ElLoading.service();
instance.interceptors.request.use(
  (config: any) => {
    // console.log("请求数据前", config.data);
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
/*响应拦截器*/
instance.interceptors.response.use(
  async (response: any) => {
    // console.log(
    //   response.data.code == 401,
    //   routes.currentRoute.value.path,
    //   "路透消息"
    // );
    // if (response.data.code == 401 && routes.currentRoute.value.path != "/") {
    //   router.push({
    //     path: "./about",
    //   });
    // }
    console.log("请求数据后code", response.data.code);
    // console.log("请求数据后", response.data);
    if (response.data.code == 200) {
      return response;
    } else {
      alert(response.data.msg)
      // showFailToast({ icon: 'none', message: response.data.msg, forbidClick: true });
      return response;
    }
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
const request = (url: string, method = "get", data = {}) => {
  const heighttypedata = ref<any>([
    "/admin/basicdata/upload/avatar",
    "/admin/basicdata/upload/file",
    "/admin/basicdata/upload/tmpfile",
  ]);
  const heighttype = ref<any>();
  if (heighttypedata.value.includes(url)) {
    heighttype.value = {
      "Content-Type": "multipart/form-data",
    };
  } else {
    heighttype.value = {
      "Content-Type": "application/json",
    };
  }
  return new Promise((reslove, reject) => {
    instance({
      url: url,
      method: method,
      headers: heighttype.value,
      [type(method)]: data,
    })
      .then((res: any) => {
        reslove(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export default request;
